<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-12">
        <h2>Reservation Review</h2>
        <br />
        <h3>
          Additional fees may apply for some changes. Calculated payment or
          refund details are displayed below for confirmation prior to
          completing the change.
        </h3>
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <ReservationInfo
          title="Existing Reservation Information"
          :reservation="existingReservation"
          :customer="customer"
        />
      </div>
      <div class="col-6">
        <ReservationInfo
          title="New Reservation Information"
          :reservation="reservation"
          :externalFees="fees"
          :customer="customer"
        />
      </div>
    </div>
    <div class="row" v-if="existingReservation">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <span class="label">Park Use Fee Paid:</span>
                {{ totalAmountPaid | formatCurrency }}
              </div>
            </div>
            <div class="row" v-for="(fee, index) in fees" :key="index">
              <div class="col-12">
                <span class="label">New {{ fee.name }}:</span>
                {{ (fee.amount * fee.quantity) | formatCurrency }}
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12" v-if="totalAmountDue < totalAmountPaid">
                <span class="label">
                  Refund Due:
                </span>
                <b>
                  {{ subTotal | formatCurrency }}
                </b>
              </div>
            </div>
            <div class="row">
              <div class="col-12" v-if="totalAmountDue > totalAmountPaid">
                <div>
                  <span class="label">
                    Pay by Credit/Debit card<sup>1</sup>:
                  </span>
                  <b>
                    {{ creditCardAmount | formatCurrency }}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Alert
      v-if="
        reservation &&
          reservation.spot.product.productClassification.applyMessageToWebsite
      "
      :alert="{
        htmlMessage: reservation.spot.product.productClassification.message,
        type: 'alert-primary'
      }"
      class="mb-3"
    />
    <div class="row">
      <div class="col col-12">
        <div class="alert-header">ALERTS AND IMPORTANT INFORMATION</div>
        <div class="policy-section" tabindex="0">
          <div class="info-label">PARK</div>
          <ul>
            <li>
              Shelter Reservations are non-refundable unless the area is closed
              by the Department, in this circumstance the Site Manager will
              contact you prior to your reservation date to cancel and issue
              your refund.
            </li>
            <li>
              Camping fees are refundable if cancellation notification is
              received no later than 3 days prior to arrival.
            </li>
            <li>
              A responsible adult (18 years of age or older) from the camping
              party must register for the party and thereby acknowledge
              compliance with the rules and regulations of the park for the
              party.
            </li>
            <li>
              For specific park information or questions regarding your
              reservation, please call the Park Office.
            </li>
            <li>
              Please click
              <a>here</a> to read the rules & regulations for camping at all
              State of Illinois campgrounds.
            </li>
          </ul>
          <div class="info-label">SITE</div>
          <br />
          <div class="info-label">CANCELLATION POLICY:</div>
          <div class="info-label">CAMPSITES, CABINS:</div>
          <p>
            Cancellations made prior to the minimum booking window for the
            facility will be provided a full refund minus the non-refundable
            $5.00 reservation fee. Cancellations made inside the minimum
            reservation window, but prior to arrival, will be charged the first
            nights campsite or cabin use fee as well as the $5.00 reservation
            fee. All remaining funds will be refunded.
          </p>
          <p>
            Cancellations (or early departures) made after the scheduled arrival
            date will be charged for the nights that have passed (or the minimum
            stay requirement, whichever is greater), plus one additional nights
            campground or cabin use fee and the $5.00 reservation fee. Any
            remaining funds will be refunded.
          </p>
          <p>
            No-shows will be charged the full amount of the campsite/cabin
            reservation fee and the $5.00 reservation fee, without refund.
          </p>
          <div class="info-label">SHELTERS:</div>
          <p>
            The reservation fee for Shelters is $50 for each day the shelter is
            reserved. All Shelter fees are non-refundable unless the area is
            closed by the Department. Upon vacating the site, shelter users are
            required to remove all personal belongings and place all trash in
            the appropriate receptacles located at the site. Failure to clean up
            the area after you leave will result in a disposal fee of $50.
            Failure to pay the disposal fee will result in denial of future
            reservations until the fee is paid in full. Shelter Rentals: Shelter
            rentals are for day use only, not overnight stays.
          </p>
          <div
            class="info-label text-uppercase"
            v-if="reservation.adaRequirementAcknowledged"
          >
            This spot is reservable only by or for persons with disabilities. By
            answering yes to this question, I am attesting that this spot is
            being reserved for a person(s) with disabilities.
          </div>
          <br />
        </div>
        <div class="form-row justify-content-center">
          <div class="col col-12 form-group text-left">
            <label>
              <input
                type="checkbox"
                :checked="alertsHaveBeenRead"
                @change="toggleAlertsRead"
                class="mr-2"
              />
              Yes, I have read and understood this important information.
              <span class="error">*</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <button
      class="btn btn-primary mr-2"
      type="button"
      :disabled="!alertsHaveBeenRead"
      @click="onCheckout(creditCardType.paymentTypeId)"
      v-if="totalAmountDue > totalAmountPaid"
    >
      Pay by Credit/Debit card<sup>1</sup> -
      {{ creditCardAmount | formatCurrency }}
    </button>
    <button
      class="btn btn-primary mr-2"
      type="button"
      :disabled="!alertsHaveBeenRead"
      @click="onCheckout(null)"
      v-if="!(totalAmountDue > totalAmountPaid)"
    >
      Confirm
    </button>
    <button class="btn btn-secondary mr-2" type="button" @click="goBack">
      Back
    </button>
    <b-button v-b-modal.cancelModal class="btn btn-secondary mr-2" type="button"
      >Cancel</b-button
    >
    <div v-if="convenienceFee != {}">
      <span class="fine-print">
        <sup>1</sup> CC Payment option includes
        {{ convenienceFee.creditCardPercentage }}% (minimum ${{
          convenienceFee.creditCardMinAmount
        }}) payment processing fee.
      </span>
    </div>
    <p class="mt-3">
      <span style="color: red">*</span> Indicates a required field
    </p>
    <div>
      <b-modal
        id="cancelModal"
        title="Cancel and Discard Changes?"
        cancel-title="Cancel and Discard Changes"
        ok-title="Continue Editing"
        @cancel="cancel"
        title-class="modalTitle"
      >
        <p class="my-4">
          Are you sure you want to cancel this modification of your reservation?
          Any changes made will not be saved.
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import PricingService from "@/services/PricingService.js";
import ProfileService from "@/services/ProfileService.js";
import LocationService from "@/services/LocationService.js";
import ReservationInfo from "@/components/reservation/ReservationInfo.vue";
import SpotReservationService from "@/services/SpotReservationService.js";
import TenantService from "@/services/TenantService.js";
import Alert from "@/components/alert/Alert.vue";

export default {
  name: "CustomerProfileReservationEditReview",
  title: "Customer Profile Reservation Edit Review",
  components: {
    ReservationInfo,
    Alert
  },
  data() {
    return {
      fees: [],
      paymentTypes: [],
      convenienceFee: {},
      feeTotal: 0,
      alertsHaveBeenRead: false,
      existingReservation: null,
      customer: null
    };
  },
  beforeCreate() {
    window.scrollTo(0, 0);
  },
  methods: {
    async getPaymentTypes() {
      const tenantService = new TenantService(this.tenantId);
      const response = await tenantService.getPaymentTypes();
      if (response?.statusCode === "Success") {
        this.paymentTypes = response.data;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    async getExistingReservationDetail() {
      const profileService = new ProfileService(this.tenantId);
      const loadedCustomer = await profileService.getCustomerByExternalUserId(
        this.externalUserId
      );
      this.customer = loadedCustomer.data;

      const response = await profileService.getReservationDetailForCustomer(
        this.customer.id,
        this.reservation.id
      );
      const reservation = response.data;
      this.existingReservation = {
        ...reservation,
        firstName: reservation.primaryOccupant.firstName,
        lastName: reservation.primaryOccupant.lastName,
        startDate: reservation.arrivalDate,
        endDate: reservation.departureDate
      };

      this.getSpotDetailsForExistingReservation();
    },
    async getSpotDetailsForExistingReservation() {
      const locationService = new LocationService(this.tenantId, null);

      const response = await locationService.getSpotDetails(
        this.existingReservation.spotId
      );

      this.existingReservation = {
        ...this.existingReservation,
        spot: response.spotDetails
      };
    },
    toggleAlertsRead() {
      this.alertsHaveBeenRead = !this.alertsHaveBeenRead;
    },
    goBack() {
      this.$router.push("/reservation-edit-occupant").catch(() => {});
    },
    cancel() {
      //Release lock
      const spotReservationService = new SpotReservationService(this.tenantId);
      const claimData = this.$store.getters["transaction/spotClaimInfo"];
      spotReservationService
        .releaseSpotClaim(claimData.spotId, claimData.lockCode)
        .then(response => {
          if (response.statusCode === "Success") {
            //Clear reservation changes
            const externalUserId = this.reservation.externalUserId;
            this.$store.dispatch("transaction/clear");
            this.existingSpotClaimed = false;

            //Route to reservation list
            this.$router
              .push("/customer-profile/" + externalUserId + "#reservations")
              .catch(() => {});
          }
        });
    },
    async getFees() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const reservation = this.$store.getters["transaction/reservation"];
      try {
        const pricingService = new PricingService(tenantId);
        const {
          oldSpotId,
          oldStartDate,
          oldEndDate,
          spotId,
          startDate,
          endDate,
          numberOfAdults,
          numberOfVehicles,
          discounts,
          oldDiscounts,
          oldNumberOfAdults
        } = reservation;
        const feeResponse = await pricingService.getFees(
          {
            oldSpotId,
            oldStartDate,
            oldEndDate,
            oldNumberOfAdults,
            customerId: reservation.customerId,
            spotId,
            startDate,
            endDate,
            numberOfAdults,
            numberOfVehicles,
            salesChannel: 1,
            discounts,
            oldDiscounts
          },
          2
        );
        if (feeResponse && feeResponse.statusCode === "Success") {
          this.fees = [
            ...feeResponse.data.filter(x => x.feeType !== "Convenience")
          ];
          this.convenienceFee = feeResponse.data.find(
            x => x.feeType === "Convenience"
          );
          this.computeTotal(this.fees);
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Fees weren't found",
            layer: "public"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    computeTotal(fees) {
      fees.forEach(fee => {
        this.feeTotal += fee.amount * fee.quantity;
      });
    },
    async onCheckout(paymentTypeId) {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const request = this.mapReservationToRequest(this.reservation);

      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new SpotReservationService(tenantId);
        let response = await spotReservationService.modifyReservation(
          this.customer.id,
          request,
          paymentTypeId
        );

        if (response && response.statusCode === "Success") {
          if (response.ccpRedirectUrl) {
            window.location = response.ccpRedirectUrl;
          } else {
            this.$router
              .push("/reservation-edit-receipt/?orderId=" + response.orderId)
              .catch(() => {});
          }
        } else if (response && response.statusCode === "Failure") {
          let errorMessage = "Error during checkout.";
          if (response.messages && response.messages.length > 0) {
            errorMessage = response.messages[0];
          }
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: errorMessage,
            layer: "public"
          });
        } else {
          window.scrollTo(0, 0);
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "public"
          });
        }
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong..." + err,
          layer: "public"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    mapReservationToRequest(reservation) {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const {
        oldSpotId,
        oldStartDate,
        oldEndDate,
        spotId,
        startDate,
        endDate,
        numberOfAdults,
        numberOfVehicles,
        equipmentLength,
        equipmentType,
        firstName,
        lastName,
        zipCode,
        alternatePhone,
        phone,
        lockCode,
        discounts,
        oldDiscounts,
        adaRequirementAcknowledged
      } = reservation;
      const spotEquipmentType = reservation.spot.spotEquipmentTypes.find(
        x => x.equipmentType.name === equipmentType
      );
      const request = {
        newReservationRequest: {
          oldSpotId,
          oldStartDate,
          oldEndDate,
          oldNumberOfAdults: this.existingReservation.numberOfAdults,
          tenantId,
          customerId: this.customer.id,
          spotId,
          startDate,
          endDate,
          numberOfAdults,
          numberOfVehicles,
          discounts,
          oldDiscounts,
          equipmentLength: +equipmentLength,
          equipmentTypeId: spotEquipmentType.equipmentType.id,
          isCustomerPrimaryOccupant: true,
          primaryOccupantFirstName: firstName,
          primaryOccupantLastName: lastName,
          primaryOccupantStreet1: this.reservation.primaryOccupant.street1,
          primaryOccupantStreet2: this.reservation.primaryOccupant.street2,
          primaryOccupantCity: this.reservation.primaryOccupant.city,
          primaryOccupantState: this.reservation.primaryOccupant.state,
          primaryOccupantZipcode: zipCode,
          primaryOccupantCountry: "USA",
          primaryOccupantEmail: this.reservation.primaryOccupant.email,
          primaryOccupantHomePhone: alternatePhone,
          primaryOccupantMobilePhone: phone,
          lockCode,
          adaRequirementAcknowledged
        },
        reservationId: this.reservation.id,
        feeOverrides: this.fees
      };
      return request;
    },
    async initializeReservation() {
      const locationService = new LocationService(this.tenantId, null);
      const response = await locationService.getSpotDetails(
        this.reservation.spotId
      );

      let reservation = {
        ...this.reservation,
        spot: response.spotDetails,
        firstName: this.reservation.primaryOccupant.firstName,
        lastName: this.reservation.primaryOccupant.lastName,
        startDate: this.reservation.startDate,
        endDate: this.reservation.endDate,
        parkName: response.spotDetails.location.longName,
        loopName: response.spotDetails.loop.name,
        spotName: response.spotDetails.name
      };

      this.$store.dispatch("transaction/setReservation", reservation);
    }
  },
  created() {
    this.getExistingReservationDetail();
    this.initializeReservation();
    this.getFees();
    this.getPaymentTypes();
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservation() {
      return this.$store.getters["transaction/reservation"];
    },
    totalAmountPaid() {
      if (!this.existingReservation) {
        return null;
      }
      let amountPaid =
        this.existingReservation.reservationFees[0].amount *
        this.existingReservation.reservationFees[0].quantity;
      return amountPaid;
    },
    totalAmountDue() {
      const result = this.fees.reduce((accumulator, fee) => {
        return accumulator + fee.amount * fee.quantity;
      }, 0);
      return result;
    },
    subTotal() {
      return Math.abs(this.totalAmountDue - this.totalAmountPaid);
    },
    externalUserId() {
      return this.$store.getters["auth/externalUserId"];
    },
    creditCardAmount() {
      return this.convenienceFee?.creditCardAmount + this.subTotal;
    },
    creditCardType() {
      return this.paymentTypes.find(x => x.paymentType === "CreditCard");
    },
    eCheckType() {
      return this.paymentTypes.find(x => x.paymentType === "Echeck");
    }
  }
};
</script>

<style scoped>
.info-label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
}
.alert-header {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
  font-size: 1.3125rem;
}
.fine-print {
  font-size: smaller;
}
</style>
<style>
.modalTitle {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
