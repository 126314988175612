<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title mt-2 mb-0">
        {{ title ? title : "Reservation Information" }}
      </h4>
    </div>
    <div class="card-body" v-if="!loading">
      <div class="row">
        <div class="col-12">
          <span class="label">Park:</span>
          {{ reservation.parkName }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">Loop:</span>
          {{ reservation.loopName }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">Spot Name:</span>
          {{ reservation.spotName }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">Spot Type(s):</span>
          {{ spotTypes }}
        </div>
      </div>
      <div class="row" v-if="!reservation.isDayUse">
        <div class="col-12">
          <span class="label">Number of Nights:</span>
          {{ numberOfNights }}
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-12">
          <span class="label">Arrival Date:</span>
          {{ reservation.startDate }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">Departure Date:</span>
          {{ reservation.endDate }}
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h2>Personal Information</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          {{ customer.firstName }} {{ customer.lastName }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          {{ customer.addresses[0].street1 }}
          <span v-if="customer.addresses[0].street2">
            <br />
            {{ customer.addresses[0].street2 }}
          </span>
          <br />
          {{ customer.addresses[0].city }}, {{ customer.addresses[0].state }}
          {{ customer.addresses[0].zipcode }}
          <br />
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-12">
          <span class="label">Phone:</span>
          {{ phoneNumber | formatPhoneNumber }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">Email:</span>
          {{ customer.email }}
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h2>Occupancy Information</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label" v-if="!!reservation.firstName"
            >Primary Occupant:</span
          >
          {{ reservation.firstName }} {{ reservation.lastName }}
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">Number of People:</span>
          {{ reservation.numberOfAdults }}
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-12">
          <h2>Equipment Information</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <span class="label">Number of Vehicles:</span>
          {{ reservation.numberOfVehicles }}
        </div>
      </div>
      <div class="row" v-if="reservation.equipmentType">
        <div class="col-12">
          <span class="label">Equipment Type:</span>
          {{ reservation.equipmentType }}
        </div>
      </div>
      <div class="row" v-if="reservation.equipmentLength">
        <div class="col-12">
          <span class="label">Equipment Length (ft):</span>
          {{ reservation.equipmentLength }}
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12">
          <h2>Amount Due</h2>
        </div>
      </div>
      <div class="row" v-for="(fee, index) in fees" :key="index">
        <div class="col-12">
          <span class="label">{{ fee.name }}:</span>
          {{ feeTotal(fee) | formatCurrency }}
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <span
        class="spinner-border spinner-border-sm mx-auto my-5"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "ReservationInfo",
  props: {
    title: { type: String, default: null },
    reservation: { type: Object },
    customer: { type: Object },
    externalFees: { type: Array }
  },
  data() {
    return {
      loading: true
    };
  },
  watch: {
    reservation() {
      if (this.loaded) {
        this.loading = false;
      }
    },
    customer() {
      if (this.loaded) {
        this.loading = false;
      }
    }
  },
  methods: {
    feeTotal(fee) {
      if (fee.amount === 0) return 0;
      else return fee.amount * fee.quantity;
    }
  },
  computed: {
    phoneNumber() {
      if (!this.customer) {
        return "";
      }
      return this.customer.customerPhoneNumbers.find(
        phone => phone.isPrimary == true
      ).number;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    numberOfNights() {
      return moment(this.reservation.endDate, "MM/DD/YYYY").diff(
        moment(this.reservation.startDate, "MM/DD/YYYY"),
        "days"
      );
    },
    spotTypes() {
      let spotTypes = "";
      if (!this.reservation.spot) {
        return spotTypes;
      }

      let spotTypeList = this.reservation.spot.spotSpotTypes;

      spotTypeList = spotTypeList.sort((a, b) =>
        a.spotType.name.trim() > b.spotType.name.trim()
          ? 1
          : a.spotType.name.trim() < b.spotType.name.trim()
          ? -1
          : 0
      );

      spotTypeList.forEach((x, i) => {
        if (i + 1 === this.reservation.spot.spotSpotTypes.length) {
          spotTypes += x.spotType.name;
        } else {
          spotTypes += x.spotType.name + ", ";
        }
      });
      return spotTypes;
    },
    fees() {
      if (this.externalFees) {
        return this.externalFees;
      } else if (this.reservation && this.reservation.reservationFees) {
        //Only grab fees from the most recent order
        const latestDate = this.reservation.reservationFees.reduce((a, b) =>
          a.dateCreated > b.dateCreated ? a : b
        ).dateCreated;

        const latestOrderId = this.reservation.reservationFees.find(
          fee => fee.dateCreated == latestDate
        ).orderId;

        return this.reservation.reservationFees.filter(
          fee => fee.feeType === "ParkUse" || fee.orderId === latestOrderId
        );
      }
      return [];
    },
    loaded() {
      return this.reservation && this.customer;
    }
  },
  mounted() {
    if (this.loaded) {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
h2 {
  font-size: 1.2rem;
  padding-bottom: 20px;
}
</style>
